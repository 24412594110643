import GraphqlTable from '@/components/graphqlTable';
import { OrdersRead } from '@/data/commerce/order.graphql';
import usePermissions from '@/providers/auth/usePermissions';
import useUserInfo from '@/providers/auth/useUserInfo';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { Order, QueryOrdersReadArgs } from '@/types/schema';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import MobileRowPanel from '../components/mobileRow';
import DeletedOrdersMultiActions from './actions/deletedOrdersMultiActions';
import useDeletedOrderActions from './actions/deletedTableActions';
import RowPanel from './rowPanel';
import { columns } from './table';

export default function DeletedOrdersModal() {
	const { staff } = useUserInfo();
	const isOwner = usePermissions( [ 'OWNER' ] );
	const isAdmin = usePermissions( [ 'ADMIN' ] );
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	
	return (
		<ResponsiveModalContainer
			title={t( 'commerce:deleted-orders' )}
			secondaryTitle={t( 'commerce:recover-your-deleted-orders' )}
			saveButtonText={t( 'common:done' )}
			onSave={async () => await queryClient.invalidateQueries( [ 'order' ] )}>
			<GraphqlTable<Order, QueryOrdersReadArgs>
				disableUrlSync
				showFooter
				searchable
				queryKey='order'
				query={OrdersRead}
				subscription={{ ORDER: staff.company.id }}
				columns={() => columns( 'deletedAt' )}
				variables={{
					options: {
						filter: {
							deletedAt: { $ne: null },
						},
					},
				}}
				useActions={useDeletedOrderActions}
				initialState={{ hiddenColumns: [ 'id', 'taxTotal', 'updatedAt', 'client.id', 'metadata.signature', 'po' ] }}
				expandedComponent={( row ) => <RowPanel hideTags row={row}/>}
				mobileRenderRow={( order ) => <MobileRowPanel data={order as any}/>}
				renderMultiActions={( orders, clearRows, selectedRows ) => (
					<DeletedOrdersMultiActions
						orders={orders}
						clearRows={clearRows}
						selectedOrders={selectedRows}
					/>
				)}
			/>
		</ResponsiveModalContainer>
	);
}
